// extracted by mini-css-extract-plugin
export var breadcrumb = "Resale-module--breadcrumb--30591";
export var btn = "Resale-module--btn--31c84";
export var cityTitle = "Resale-module--city-title--c35cf";
export var container = "Resale-module--container--e3d38";
export var core = "Resale-module--core--242c8";
export var description = "Resale-module--description--a6761";
export var enterprise = "Resale-module--enterprise--faafc";
export var enterprises = "Resale-module--enterprises--29aef";
export var form = "Resale-module--form--450ed";
export var header = "Resale-module--header--98e3b";
export var image = "Resale-module--image--5d5c5";
export var list = "Resale-module--list--c68ed";
export var location = "Resale-module--location--e0351";
export var map = "Resale-module--map--ca11e";
export var price = "Resale-module--price--d58d2";
export var section = "Resale-module--section--5968d";
export var slide = "Resale-module--slide--0bac3";
export var text = "Resale-module--text--3d8f5";
export var title = "Resale-module--title--c0b83";
export var whatsapp = "Resale-module--whatsapp--5f1d5";
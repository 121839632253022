import React from 'react'
import { PageProps } from 'gatsby'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import { useContext } from '~/hooks'
import nl2br from 'react-nl2br'
import {
  Head,
  Breadcrumb,
  Lazyload,
  Button,
  Map,
  Gallery,
  AttendanceForm,
  Form,
  Link,
  Footer,
} from '~/components'
import { scroller } from 'react-scroll'
import Slider, { ArrowType } from '@fil1pe/react-slider'
import { Enterprise } from '~/pages/_revenda'
import cn from 'classnames'
import * as st from '~/assets/styl/Resale.module.styl'

interface Zoom {
  legend: string
  large: string
  zoom: string
}

interface ResaleEnterprise {
  id: string
  slug: string
  name: string
  description: string
  price: string
  address: string
  number: string
  cep: string
  latitude: string
  longitude: string
  district: string
  city: string
  state: {
    name: string
    acronym: string
  }
  image: string
  galleryResaleImmobile: Array<Zoom>
  textImmobile: Array<string>
  galleryResaleCommonArea: Array<Zoom>
  textCommonArea: Array<string>
}

const Resale = ({
  location,
  pageContext,
  '*': slug,
}: PageProps & { '*': string }) => {
  const { result: enterprise }: { result: ResaleEnterprise } = useApi(
    {
      result: pageContext,
    },
    'resales/' + (slug || (pageContext as ResaleEnterprise).slug)
  )

  const { setLoading } = useContext()
  setLoading(!enterprise?.name)

  const mobile = !useMediaQuery({
    query: '(min-width: 951px)',
  })

  const { resales }: { resales: Array<ResaleEnterprise> } = useApi(
    {
      resales: useStaticQuery(graphql`
        query {
          allResales {
            nodes {
              slug
              name
              description
              price
              address
              cep
              latitude
              longitude
              district
              city
              state {
                name
                acronym
              }
              image
            }
          }
        }
      `).allResales.nodes,
    },
    'resales'
  )

  return enterprise?.name ? (
    <>
      <Head
        location={location}
        title={'Revenda de imóveis - ' + process.env.GATSBY_SITE_NAME}
      />

      <div className={st.core}>
        <div className={st.container}>
          <Breadcrumb className={st.breadcrumb} />
          <span className={st.title}>Revenda de imóveis</span>
          <header className={st.header}>
            <Lazyload src={enterprise.image} className={st.image} />
            <div className={st.text}>
              <h1>{enterprise.name}</h1>
              {enterprise.city && (
                <p className={st.location}>
                  {enterprise.district ? enterprise.district + ' - ' : ''}
                  {enterprise.city}
                  {enterprise.state ? ' - ' + enterprise.state.acronym : ''}
                </p>
              )}
              {enterprise.price && (
                <p className={st.price}>
                  {'R$ ' +
                    parseFloat(enterprise.price)
                      .toFixed(2)
                      .toString()
                      .replace('.', ',')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                </p>
              )}
              {enterprise.description && (
                <p className={st.description}>
                  {nl2br(enterprise.description)}
                </p>
              )}
              <Button
                className={st.btn}
                onClick={() =>
                  scroller.scrollTo('RecebaInformacoes', {
                    smooth: true,
                    duration: 500,
                    offset:
                      -0.65 * document.getElementById('menu-bar').offsetHeight,
                  })
                }
              >
                Receba mais informações
              </Button>
              <Button
                href={`https://api.whatsapp.com/send?text=${(
                  'Confira a revenda deste imóvel na Holder Construtora e Incorporadora: https://' +
                  process.env.GATSBY_SITE_URL +
                  location.pathname
                ).replace(/ /g, '%20')}`}
                external
                className={st.whatsapp}
              >
                Compartilhar no WhatsApp
              </Button>
            </div>
          </header>
        </div>
      </div>

      {Boolean(enterprise.latitude && enterprise.longitude) && (
        <section className={st.section}>
          <div className={st.container}>
            <h2>Localização</h2>
            <p>
              {enterprise.address}
              {enterprise.number ? ', ' + enterprise.number : ''} -{' '}
              {enterprise.district ? enterprise.district + ' - ' : ''}
              {enterprise.city} - {enterprise.state?.acronym}
            </p>
            <div className={st.map}>
              <Map
                location={{
                  lat: enterprise.latitude,
                  lng: enterprise.longitude,
                }}
                resale
              />
            </div>
          </div>
        </section>
      )}

      {Boolean(
        enterprise.galleryResaleImmobile?.length ||
          enterprise.textImmobile?.length
      ) && (
        <section className={st.section}>
          <div className={st.container}>
            <h2>O imóvel</h2>
            {Boolean(enterprise.textImmobile?.length) && (
              <ul className={st.list}>
                {enterprise.textImmobile.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ul>
            )}
            {Boolean(enterprise.galleryResaleImmobile?.length) && (
              <Gallery
                items={enterprise.galleryResaleImmobile.map(
                  ({ large: url, legend, zoom }) => ({
                    url,
                    legend,
                    zoom,
                  })
                )}
              />
            )}
          </div>
        </section>
      )}

      {Boolean(
        enterprise.galleryResaleCommonArea?.length ||
          enterprise.textCommonArea?.length
      ) && (
        <section className={st.section}>
          <div className={st.container}>
            <h2>Áreas Comuns</h2>
            {Boolean(enterprise.textCommonArea?.length) && (
              <ul className={st.list}>
                {enterprise.textCommonArea.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ul>
            )}
            {Boolean(enterprise.galleryResaleCommonArea?.length) && (
              <Gallery
                items={enterprise.galleryResaleCommonArea.map(
                  ({ large: url, legend, zoom }) => ({
                    url,
                    legend,
                    zoom,
                  })
                )}
              />
            )}
          </div>
        </section>
      )}

      {(resales || []).length > 1 && (
        <section className={st.section}>
          <div className={st.container}>
            <h2>Veja outros imóveis de revenda</h2>

            <Slider
              className="slider"
              slidesToShow={mobile ? 1 : 2}
              renderArrow={({ className, ...props }, type) => (
                <button
                  {...props}
                  className={cn(
                    className,
                    type === ArrowType.Next ? 'next' : 'prev'
                  )}
                ></button>
              )}
            >
              {resales
                .filter(({ slug: thisSlug }) => slug !== thisSlug)
                .map(
                  (
                    {
                      name,
                      image,
                      district,
                      city,
                      state: { acronym: state },
                      price,
                      description,
                      slug,
                    },
                    key
                  ) => (
                    <li key={key}>
                      <Enterprise
                        name={name}
                        image={image}
                        location={`${
                          district ? district + ' - ' : ''
                        }${city} - ${state}`}
                        price={
                          price
                            ? 'R$ ' +
                              parseFloat(price)
                                .toFixed(2)
                                .toString()
                                .replace('.', ',')
                                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                            : ''
                        }
                        description={description}
                        slug={slug}
                      />
                    </li>
                  )
                )}
            </Slider>
          </div>
        </section>
      )}

      <AttendanceForm title="Receba mais informações" id="RecebaInformacoes">
        <Form
          path="informations"
          idPrefix="contact"
          button={<Button>Enviar mensagem</Button>}
          loadingButton={<Button>Enviando...</Button>}
          hiddenInputs={{
            resale: enterprise.id,
          }}
          inputs={[
            {
              name: 'name',
              label: 'Nome*',
              className: 'wide',
            },
            {
              name: 'email',
              label: 'E-mail*',
              type: 'email',
              className: 'wide',
            },
            {
              name: 'whatsapp',
              label: 'WhatsApp*',
              mask: 'phone',
              className: 'wide',
            },
            {
              name: 'message',
              label: 'Mensagem*',
              type: 'textarea',
              className: 'wide',
            },
            {
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link href="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: 'wide',
            },
          ]}
          className={st.form}
        />
      </AttendanceForm>

      <Footer />
    </>
  ) : (
    <></>
  )
}

export default Resale

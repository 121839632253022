import React, { Fragment } from 'react'
import { PageProps } from 'gatsby'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import ResaleDetails from './_revenda-detalhes'
import { Head, Breadcrumb, Lazyload, Button, Footer } from '~/components'
import * as st from '~/assets/styl/Resale.module.styl'

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

interface Props {
  name: string
  image: string
  location: string
  price: string
  description: string
  slug: string
}

export const Enterprise = ({
  name,
  image,
  location,
  price,
  description,
  slug,
}: Props) => (
  <div className={st.enterprise}>
    <Lazyload src={image} className={st.image} />
    <div className={st.text}>
      <h3>{name}</h3>
      <p className={st.location}>{location}</p>
      <p className={st.price}>{price}</p>
      <p className={st.description}>{description}</p>
      <Button href={`/revenda/${slug}/`} className={st.btn}>
        Conheça
      </Button>
    </div>
  </div>
)

interface ResaleEnterprise {
  slug: string
  name: string
  description: string
  price: string
  address: string
  cep: string
  latitude: string
  longitude: string
  district: string
  city: string
  state: {
    name: string
    acronym: string
  }
  image: string
}

const Resale = (props: PageProps & { '*': string }) => {
  const { location, pageContext, '*': slug } = props

  if (slug || (pageContext as { [key: string]: never }).slug)
    return <ResaleDetails {...props} />

  const { resales }: { resales: Array<ResaleEnterprise> } = useApi(
    {
      resales: useStaticQuery(graphql`
        query {
          allResales {
            nodes {
              slug
              name
              description
              price
              address
              cep
              latitude
              longitude
              district
              city
              state {
                name
                acronym
              }
              image
            }
          }
        }
      `).allResales.nodes,
    },
    'resales'
  )

  const enterprises: {
    [key: string]: Array<ResaleEnterprise>
  } = groupBy(
    resales.map((resale) => ({
      ...resale,
      cityState: `${resale.city} - ${resale.state.acronym}`,
    })),
    'cityState'
  )

  return (
    <>
      <Head
        location={location}
        title={'Revenda de imóveis - ' + process.env.GATSBY_SITE_NAME}
      />
      <section className={st.core}>
        <div className={st.container}>
          <Breadcrumb className={st.breadcrumb} />
          <h1 className={st.title}>Revenda de imóveis</h1>
          {Object.keys(enterprises).map((key, index) => (
            <Fragment key={index}>
              <h2 className={st.cityTitle}>{key}</h2>
              <ul className={st.enterprises}>
                {enterprises[key].map(
                  (
                    {
                      name,
                      image,
                      district,
                      city,
                      state: { acronym: state },
                      price,
                      description,
                      slug,
                    },
                    key
                  ) => (
                    <li key={key}>
                      <Enterprise
                        name={name}
                        image={image}
                        location={`${
                          district ? district + ' - ' : ''
                        }${city} - ${state}`}
                        price={
                          price
                            ? 'R$ ' +
                              parseFloat(price)
                                .toFixed(2)
                                .toString()
                                .replace('.', ',')
                                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                            : ''
                        }
                        description={description}
                        slug={slug}
                      />
                    </li>
                  )
                )}
              </ul>
            </Fragment>
          ))}
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Resale
